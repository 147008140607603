export default {
	totalOrders: (state) => state.centralizator.orders.total != null ? state.centralizator.orders.total : 0,
	totalProducts: (state) => state.centralizator.products.total != null ? state.centralizator.products.total : 0,
	ordersChartDays: (state) => {
		return state.compound.orders.chart.days != null ? state.compound.orders.chart.days : [];
	},
	ordersChartFinished: (state) => {
		return state.compound.orders.chart.data != null && state.compound.orders.chart.data.finished != null ? state.compound.orders.chart.data.finished : [];
	},
	ordersChartCanceled: (state) => {
		return state.compound.orders.chart.data != null && state.compound.orders.chart.data.canceled != null ? state.compound.orders.chart.data.canceled : [];
	},
	ordersChartStorno: (state) => {
		return state.compound.orders.chart.data != null && state.compound.orders.chart.data.storno != null ? state.compound.orders.chart.data.storno : [];
	},
	productsChartDays: (state) => {
		return state.compound.products.chart.days != null ? state.compound.products.chart.days : [];
	},
	productsChartViews: (state) => {
		return state.compound.products.chart.data != null && state.compound.products.chart.data.views != null ? state.compound.products.chart.data.views : [];
	},
	productsChartFavorites: (state) => {
		return state.compound.products.chart.data != null && state.compound.products.chart.data.favorites != null ? state.compound.products.chart.data.favorites : [];
	},
	productsChartCart: (state) => {
		return state.compound.products.chart.data != null && state.compound.products.chart.data.cart != null ? state.compound.products.chart.data.cart : [];
	}
};