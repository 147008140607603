import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = () => ({
    centralizator: {
        loading: true,
        loaded: false,
        announcements: {},
        orders: {},
        products: {},
        returns: {},
        service: {},
        questions: {},
        cor: {},
        payments: {},
		payouts: {
			vat: '',
			payout: 0,
			date: ''
		}
    },
    compound: {
        loading: true,
        loaded: false,
        orders: {
            chart: {},
            stats: {},
        },
        products: {
            chart: {},
            stats: {},
        },
    },
});

export function createDashboardStore() {
    return {
        namespaced: true,
        state: initialState,
        actions,
        getters,
        mutations,
    };
}
