<template>
    <div class="app-container">
        <el-row :gutter="20" class="dashboard-group">
            <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="18">
                <div class="grid-content">
                    <announcements></announcements>
                </div>
                <!-- <div class="grid-content">
                    <black-friday></black-friday>
                </div> -->
                <div class="grid-content">
                    <products-free-advertise :inDashboard="true"></products-free-advertise>
                </div>
                <div class="grid-content">
                    <backlinks></backlinks>
                </div>
                <div class="grid-content">
                    <orders-charts></orders-charts>
                </div>
                <div class="grid-content">
                    <products-charts></products-charts>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="6">
                <div class="grid-content">
                    <payout></payout>
                </div>
                <div class="grid-content">
                    <extension-card></extension-card>
                </div>
                <div class="separator"></div>
                <div class="grid-content">
                    <github-doc></github-doc>
                </div>
                <div class="separator"></div>
                <div class="grid-content">
                    <Billing />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { createDashboardStore } from '@/store/modules/dashboard/index.js';

const ProductsCharts = () => import('./components/ProductsCharts');
const OrdersCharts = () => import('./components/OrdersCharts');
const Announcements = () => import('./components/Announcements');
const GithubDoc = () => import('./components/GithubDoc.vue');
const Billing = () => import('./components/BillingCard.vue');
const Backlinks = () => import('./components/Backlinks.vue');
const ExtensionCard = () => import('./components/ExtensionCard.vue');
const Payout = () => import('./components/Payout.vue');
const ProductsFreeAdvertise = () => import('@/components/ProductsFreeAdvertising/Index.vue');
// const BlackFriday = () => import('./components/BlackFriday.vue');

import { mapState } from 'vuex';
export default {
    name: 'dashboard',
    components: {
        Announcements,
        OrdersCharts,
        GithubDoc,
        Billing,
        ProductsCharts,
        Backlinks,
        ExtensionCard,
        Payout,
        ProductsFreeAdvertise,
        // BlackFriday
    },
    methods: {
        async getDashboardCompound() {
            try {
                await this.$store.dispatch('dashboard/GetDashboardCompound');
            } catch (err) {}
        },
    },
    computed: {
        ...mapState({
            compoundLoaded: (state) => state.dashboard.compound.loaded,
            compoundLoading: (state) => state.dashboard.compound.loading,
        }),
    },
    beforeCreate() {
        this.$store.registerModule('dashboard', createDashboardStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('dashboard');
    },
    mounted() {
        if (!this.compoundLoaded) {
            this.getDashboardCompound();
        }
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
    margin-bottom: 20px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

.dashboard-group {
    margin: 0 !important;

    ::v-deep .stats-col {
        margin-top: 50px;
    }

    ::v-deep .dd-title {
        padding-left: 5px;
        vertical-align: middle;
    }

    ::v-deep .dashboard-icon-card {
        font-size: 24px;
        vertical-align: middle;
        color: #ff601d;
    }

    @media only screen and (max-width: 560px) {
        ::v-deep .card-panel-footer {
            display: none;
        }
    }

    @media screen and (max-width: 580px) {
        ::v-deep .panel-group .card-panel {
            height: auto !important;
            padding-bottom: 10px;

            .card-panel-icon-wrapper {
                display: none;
            }

            .card-panel-description {
                float: none !important;
                text-align: center;
                margin: 10px !important;

                .card-panel-text {
                    font-size: 1.2em !important;
                }
            }
        }

        ::v-deep .stats-col {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 350px) {
        ::v-deep .panel-group .card-panel {
            .card-panel-description {
                .card-panel-text {
                    font-size: 1em !important;
                }

                .card-panel-num {
                    font-size: 16px !important;
                }
            }
        }

        ::v-deep .stats-col {
            margin-top: 0px;
        }
    }

    ::v-deep .panel-group {
        margin-top: 18px;

        .card-panel-col {
            margin-bottom: 32px;
        }

        .card-panel {
            height: 85px;
            cursor: pointer;
            font-size: 12px;
            position: relative;
            overflow: hidden;
            color: #666;
            border: 1px solid #ebeef5;

            .icon-complete {
                color: #40c9c6;
            }

            .icon-new {
                color: #36a3f7;
            }

            .icon-cancel-order {
                color: #f4516c;
            }

            .icon-storno {
                color: #909399;
            }

            .card-panel-icon-wrapper {
                float: left;
                transition: all 0.38s ease-out;
                border-radius: 6px;
                position: absolute;
                bottom: 1em;
                left: 1em;
            }

            .card-panel-icon {
                float: left;
                font-size: 4.5em;
                opacity: 0.6;
            }

            .card-panel-description {
                float: right;
                font-weight: bold;
                margin: 10px 15px;
                margin-left: 0px;

                .card-panel-text {
                    line-height: 18px;
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 1.2em;
                    margin-bottom: 5px;
                }

                .card-panel-num {
                    font-size: 20px;
                    float: right;
                }
            }

            .card-panel-footer {
                position: absolute;
                bottom: 0;
                right: 0;
                margin: 6px 15px;
            }
        }
    }
}
</style>
