import fetch from '@/utils/fetch';

export function getAnnouncements() {
	return fetch({
		url: '/dashboard/getAnnouncements',
		method: 'post',
	});
}

export function setAnnouncementRead(data) {
	return fetch({
		url: '/dashboard/setAnnouncementRead',
		method: 'post',
		data: data
	});
}

export function getDashboardCompound() {
	return fetch({
		url: '/dashboard/getDashboardCompound',
		method: 'post',
	});
}

export function getPayoutEstimate() {
	return fetch({
		url: '/dashboard/getPayoutEstimate',
		method: 'post',
	});
}