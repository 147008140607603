import {
	initialState
} from './index';

export default {
	SET_ANNOUNCEMENTS: (state, data) => {
		state.centralizator.announcements = data;
	},
	SET_ORDERS: (state, data) => {
		state.centralizator.orders = data;
	},
	SET_PRODUCTS: (state, data) => {
		state.centralizator.products.total = parseInt(data.waiting.count) + parseInt(data.rejected.count);
		state.centralizator.products.waiting = {
			total: data.waiting.count,
			data: data.waiting.data
		};
		state.centralizator.products.rejected = {
			total: data.rejected.count,
			data: data.rejected.data
		};
	},
	SET_QUESTIONS: (state, data) => {
		state.centralizator.questions = data;
	},
	SET_RETURNS: (state, data) => {
		state.centralizator.returns = data;
	},
	SET_SERVICE: (state, data) => {
		state.centralizator.service = data;
	},
	SET_COR: (state, data) => {
		state.centralizator.cor = data;
	},
	SET_LOADING: (state, status) => {
		state.centralizator.loading = status;
	},
	SET_LOADED: (state, status) => {
		state.centralizator.loaded = status;
	},
	SET_ORDERS_COMPOUND: (state, data) => {
		state.compound.orders.stats = data;
	},
	SET_ORDERS_CHART_DATA: (state, data) => {
		state.compound.orders.chart = data;
	},
	SET_PRODUCTS_COMPOUND: (state, data) => {
		state.compound.products.stats = data;
	},
	SET_PRODUCTS_CHART_DATA: (state, data) => {
		state.compound.products.chart = data;
	},
	SET_COMPOUND_LOADING: (state, status) => {
		state.compound.loading = status;
	},
	SET_COMPOUND_LOADED: (state, status) => {
		state.compound.loaded = status;
	},
	SET_PAYOUT_ESTIMATE: (state, data) => {
		state.centralizator.payouts.vat = data.vat
		state.centralizator.payouts.payout = data.payout
		state.centralizator.payouts.date = data.date
	},
	RESET_STATE: (state) => {
		const iState = initialState();
		for (const prop in state) {
			if (typeof iState[prop] !== 'undefined' && prop !== 'token') {
				state[prop] = iState[prop];
			}
		}
	},
};