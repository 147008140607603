import {
	getAnnouncements,
	setAnnouncementRead,
	getDashboardCompound,
	getPayoutEstimate
} from '@/api/dashboard';

import {
	saveAffiliateLink,
	checkSiteUrl
} from '@/api/cont';

export default {
	GetAnnouncements({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getAnnouncements().then((res) => {
				if (res == null || res.message == null) {
					return reject(new Error('Eroare la preluarea datelor pentru centralizator'));
				}
				commit('SET_ANNOUNCEMENTS', res.message.announcements);
				commit('SET_ORDERS', res.message.orders);
				commit('SET_PRODUCTS', res.message.products);
				commit('SET_RETURNS', res.message.return);
				commit('SET_SERVICE', res.message.service);
				commit('SET_QUESTIONS', res.message.questions);
				commit('SET_COR', res.message.cancel_orders_requests);
				commit('SET_LOADING', false);
				commit('SET_LOADED', true);
				return resolve(res);
			}).catch((err) => {
				commit('SET_LOADING', false);
				return reject(err);
			});
		});
	},
	SetAnnouncementRead({
		commit
	}, data) {
		return new Promise((resolve, reject) => {
			setAnnouncementRead(data).then((res) => {
				return resolve(res);
			});
		});
	},
	GetDashboardCompound({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getDashboardCompound().then((res) => {
				if (res == null || res.message == null) {
					return reject(new Error('Eroare la preluarea datelor pentru comenzi'));
				}
				commit('SET_ORDERS_COMPOUND', res.message.orders.compound);
				commit('SET_ORDERS_CHART_DATA', res.message.orders.stats);
				commit('SET_PRODUCTS_COMPOUND', res.message.products.compound);
				commit('SET_PRODUCTS_CHART_DATA', res.message.products.stats);
				commit('SET_COMPOUND_LOADING', false);
				commit('SET_COMPOUND_LOADED', true);
			});
		}).catch((err) => {
			commit('SET_COMPOUND_LOADING', false);
			return reject(new Error(err.message));
		});
	},
	GetProductsDashboardData({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getProductsDashboardData().then((res) => {

			}).catch((err) => {

			});
		});
	},
	SaveAffiliateLink({
		commit
	}, link) {
		return new Promise((resolve, reject) => {
			saveAffiliateLink({
				link
			}).then((res) => {
				resolve();
				commit('user/SET_USER_LINK', link, {
					root: true
				});
			}).catch((err) => {
				reject(err);
			});
		});
	},
	CheckSiteUrl({
		commit
	}) {
		return new Promise((resolve, reject) => {
			checkSiteUrl().then((res) => {
				resolve(res);
			}).catch((err) => {
				reject(err);
			});
		});
	},
	GetPayoutEstimate({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getPayoutEstimate().then((res) => {
				if (res == null || res.message == null) {
					return reject(new Error('Eroare la preluarea datelor pentru payout'));
				}
				commit('SET_PAYOUT_ESTIMATE', res.message);
			});
		}).catch((err) => {
			// commit('SET_COMPOUND_LOADING', false);
			return reject(new Error(err.message));
		});
	}
};